html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #171515; /* Set background color to black */
}

#root {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #171515;
  color: #f5f5f5; /* Set font color to #f5f5f5 */
  font-size: 18px; /* Increase base font size */
}

#addbutton {
  display: inline-flex; /* Use flexbox for centering */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  background-color: #16a35f;
  color: #f5f5f5;
  border: none;
  border-radius: 50%; /* Make the button circular */
  font-size: 30px;
  cursor: pointer;
  width: 25px; /* Set equal width and height */
  height: 25px; /* Set equal width and height */
  padding-bottom: 5px; /* Remove padding */
}

#addbutton2 {
  display: inline-flex; /* Use flexbox for centering */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  background-color: #16a35f;
  color: #f5f5f5;
  border: none;
  border-radius: 50%; /* Make the button circular */
  font-size: 30px;
  cursor: pointer;
  width: 30px; /* Set equal width and height */
  height: 30px; /* Set equal width and height */
  padding-bottom: 0px; /* Remove padding */
}

.button-common:hover {
  background-color: #138a4d; /* Darker shade for hover state */
}

.button-common:active {
  background-color: #0f6e3e; /* Even darker shade for active state */
  transform: scale(0.95); /* Slightly shrink the button when clicked */
}

.button-common:focus {
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 0 3px rgba(21, 156, 94, 0.4); /* Add custom focus outline */
}

.content {
  display: flex;
  flex-direction: row;
  flex: 1; /* Allow the content to grow and fill available space */
  background-color: #171515;
  margin-top: 5px;
}

.map-container {
  flex: 1; /* Allow the map container to grow and fill available space */
  padding-top: 25px;
  padding-bottom: 55px;
  background-color: #171515;
}

.styled-select {
  width: 90%;
  margin-top: 5px;
  padding: 10px; /* Reduced padding */
  font-size: 15px; /* Reduced font size */
  border: 1px solid #ccc;
  border-radius: 10px; /* Increased border-radius for rounder corners */
  background-color: #fff;
  color: #333;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px 10px; /* Adjusted size of the arrow */
}

.styled-select2 {
  width: 90%;
  margin-top: 5px;
  padding: 10px; /* Reduced padding */
  font-size: 15px; /* Reduced font size */
  border: 1px solid #ccc;
  border-radius: 10px; /* Increased border-radius for rounder corners */
  background-color: #fff;
  color: #333;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px 10px; /* Adjusted size of the arrow */
}

.styled-select:focus {
  border-color: #007bff;
  outline: none;
}

.data-summary-container {
  padding: 20px; /* Match the height of the map container */
  position: relative; /* Ensure the container is the reference for absolute positioning */
  justify-content: left;
  align-items: left;
}

.data-summary-container p {
  width: 95%;
  margin: 0 auto; /* Center the paragraph */
  line-height: 1.6; /* Increase line height for more space between lines */
}

.data-summary-container li {
  width: 95%;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  color: #66b2ff; /* Lighter shade of blue */
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 45px;
  padding-top: 10px;
}

.households {
  font-size: 12px;
  padding-left: 5px; 
  padding-top: 30px;
}

.Title {
  font-size: 45px;
  padding-top: 10px;
}

.GameResults {
  font-size: 20px;
  padding: 20px;
}

.question-button {
  display: inline-block;
  background: none;
  border: 2px solid #f5f5f5;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: #f5f5f5;
  padding: 2px 8px;
  transition: background-color 0.3s, color 0.3s;
}

.question-button:hover {
  background-color: #f5f5f5;
  color: #171515;
}

.question-button:active {
  background-color: lightgray;
  color: #171515;
}

/* Center the data-summary-container on desktop screens */

/* Center the data-summary-container on desktop screens */
/* Center the data-summary-container on desktop screens */
@media (min-width: 1024px) {
  .data-summary-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto; /* Center the container */
    align-items: left; /* Center child elements */
    justify-content: space-between;
  }

  .data-summary-container h1, .data-summary-container h3 {
    text-align: center;
    width: 100%;
  }

  .data-summary-container hr {
    width: 100%;
    margin: 0 auto;
  }

  .data-summary-container p {
    width: 95%;
    margin: 0 auto; /* Center the paragraph */
    line-height: 1.6; /* Increase line height for more space between lines */
  }

  #addbutton2 {
    display: inline-flex; /* Use flexbox for centering */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    background-color: #16a35f;
    color: #f5f5f5;
    border: none;
    border-radius: 50%; /* Make the button circular */
    font-size: 30px;
    cursor: pointer;
    width: 30px; /* Set equal width and height */
    height: 30px; /* Set equal width and height */
    padding: 0; /* Remove padding */
  }

  .households {
    font-size: 12px;
    padding-left: 5px; 
    padding-top: 90px;
  }

  .centered-content {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100px;
    padding-top: 25px;
  }

  .styled-select {
    width: 40%;
    margin-right: 10px;
  }

  .styled-select2 {
    width: 20%;
  }

  .results {
    width: 75%;
  }

  .moredeats {
    padding-top: 15px;
  } 

  .accordion-content {
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .capacity-circle {
    position: relative; 
    left: 50%; /* Center the circle horizontally */
    transform: translateX(-50%); /* Adjust as needed */
    width: 50px; /* Adjust as needed */
    height: 50px; /* Adjust as needed */
    background-color: #FF4040;
    color: #f5f5f5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px; /* Adjust as needed */
    font-weight: bold;
    text-align: center;
    padding: 5px;
  }
}

.moredeats {
  padding-top: 15px;
} 

.accordion-content {
  padding-top: 20px;
}

.capacity-circle {
  position: absolute; /* Position absolutely within the container */
  top: 5px; /* Adjust as needed */
  right: 5px; /* Adjust as needed */
  width: 50px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  background-color: #FF4040;
  color: #f5f5f5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px; /* Adjust as needed */
  font-weight: bold;
  text-align: center;
  padding: 5px;
}

.ctabutton {
  padding: 10px 20px;
  font-size: 20px; /* Increased font size */
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.mainfunctionbutton {
  padding: 10px 20px;
  font-size: 20px;
  border-radius: 5px; /* round the edges */
  border: none; /* make the button look like a button */
  cursor: pointer;
}

.full-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #171515;
}

.hidden {
  display: none;
}

.button-container {
  display: flex;
  gap: 10px; /* Adjust the gap as needed */
  margin-top: 20px; /* Optional: Add some margin to the top */
  margin-bottom: 0px; /* Optional: Add some margin to the top */
  justify-content: left; /* Center the buttons horizontally */
  margin-left: 20px;
  z-index: 1001;
}

.full-screen-button,
.toggle-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-color: #0080FF;
  color: #f5f5f5;
  transition: background-color 0.3s, transform 0.1s; /* Smooth transition for background color and transform */
}

.full-screen-button:hover,
.toggle-button:hover {
  background-color: #005bb5; /* Darker blue on hover */
}

.full-screen-button:active,
.toggle-button:active {
  background-color: #004494; /* Even darker blue on active */
  transform: scale(0.95); /* Slightly shrink the button when clicked */
}

.full-screen-button:focus,
.toggle-button:focus {
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 0 3px rgba(0, 128, 255, 0.4); /* Add custom focus outline */
}

.consent-banner {
  position: fixed;
  bottom: 20px; /* Add some space from the bottom */
  right: 20px; /* Add some space from the right */
  width: 250px; /* Slightly increase the width */
  height: auto; /* Adjust height based on content */
  background-color: rgba(125, 122, 122, 0.95); /* Slightly more transparent background */
  color: #000;
  padding: 20px; /* Increase padding for better spacing */
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: space-between;
  align-items: center;
  border-radius: 15px; /* Slightly reduce border radius */
  z-index: 1000; /* Ensure the banner is on top */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  transition: transform 0.3s ease, opacity 0.3s ease; /* Smooth transition for appearance */
  opacity: 1; /* Ensure the banner is fully opaque */
}

.consent-banner p {
  margin: 0;
  padding: 10px;
  text-align: center; /* Center the text */
  font-size: 14px; /* Slightly smaller font size */
}

.consent-banner .button-container {
  display: flex;
  flex-direction: row; /* Align buttons side by side */
  justify-content: space-between; /* Distribute space between buttons */
  width: 100%; /* Ensure buttons take full width */
}

.consent-banner button {
  border-radius: 10px; /* Reduce border radius for buttons */
  background-color: #171515; /* Dark background */
  color: #f5f5f5; /* Light text */
  border: none; /* Remove border */
  text-align: center; /* Center text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Inline-block display */
  font-size: 14px; /* Slightly smaller font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, transform 0.1s; /* Smooth transition */
  flex: 1; /* Allow buttons to grow and take equal space */
  margin: 0 3px; /* Add some space between buttons */
}

.consent-banner button:hover {
  background-color: #45a049; /* Darker green on hover */
}

.consent-banner button:active {
  transform: scale(0.95); /* Slightly shrink the button when clicked */
}

.white-hr {
  margin-top: 20px;
  border: none; /* Remove default border */
  border-top: 2px solid #f5f5f5; /* Add a solid #f5f5f5 border */
  margin: 2px 0; /* Optional: Adjust margin as needed */
  width: 100%;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-content {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
  text-align: center;
  color: #171515; /* Ensure text color is set to black */
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Center the game container */
.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.table-title {
  margin-block-start: 0;
  text-align: left; /* Center the title */
  width: 100%;
}


.table-container {
  width: 30%; /* Adjust the width as needed */
  background-color: #eceaea;
  max-width: 500px; /* Maximum width for the table */
}